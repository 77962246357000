import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Place {
  id?: string;
  modified: any;
  deleted: boolean;
  createdBy: any;
  name: string;
  address: any;
  geopoint: any;
}

export interface MapyPlace {
  id: string;
  name: string;
  address: any;
  latitude: any;
  longitude: any;
}

@Injectable({
  providedIn: 'root'
})
export class PlaceService {

  places: Observable<Place[]>;
  placesCollection: any;

  constructor(private afs: AngularFirestore) {
    this.places = this.getPlaces(1000);
  }

  // Get a some single place
  async getPlace(id) {

  }

  // Get a some places using snapshot changes to get the ID.
  getPlaces(count) {
    console.log('Getting all the places');
    this.placesCollection = this.afs.collection<Place>('places', ref => ref.where('deleted', '==', false).orderBy('modified', 'desc').limit(count));
    return this.placesCollection.snapshotChanges().pipe(map((actions: any) => actions.map(a => { return { id: a.payload.doc.id, ...a.payload.doc.data() }; })));
  }

  // Create or update place
  async setPlace(placeId, createdBy, name, address, latitude, longitude) {

    const modified = firebase.firestore.FieldValue.serverTimestamp();
    const deleted = false;
    const geopoint = new firebase.firestore.GeoPoint(latitude, longitude);

    // Review document data
    const place: Place = { modified, deleted, createdBy, name, address, geopoint };

    // Custom doc ID for relationship
    const placePath = `places/${placeId}`;

    // Set the data, return the promise
    return await this.afs.doc(placePath).set(place);
  }

}
