import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { ReviewService } from '../services/review.service';
import { PlaceService } from '../services/place.service';
import { map } from "rxjs/operators"; 
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranService } from '../services/tran.service';

//to setup Pexeso
declare function jsFunctionSetupPexeso(): any;

@Component({
  selector: 'app-pexeso',
  templateUrl: './pexeso.component.html',
  styleUrls: ['./pexeso.component.scss']
})
export class PexesoComponent implements OnInit {

  
  constructor(private route: ActivatedRoute, 
    public auth: AuthService, 
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private reviewService: ReviewService, 
    private placeService: PlaceService,
    private router: Router,
    public tranSerivce: TranService,
    public sanitizer: DomSanitizer) {}

  async ngOnInit() {
    this.tranSerivce.loadLang();

    jsFunctionSetupPexeso();
  }
}
