import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-super-secret',
  templateUrl: './super-secret.component.html',
  styleUrls: ['./super-secret.component.scss']
})
export class SuperSecretComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
