import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app'; 
import 'firebase/firestore';

export interface Review {
  modified: any;
  deleted: boolean;
  userId: any;
  placeId: any;
  placeName: string;
  name: string;
  comment: string;
  price: number;
  meatReview: number;
  sauceReview: number;
  dumplingReview: number;
  creamReview: number;
  cranberryReview: number;
  overallReview: number;
}


@Injectable({
  providedIn: 'root'
})
export class ReviewService {

  constructor(private afs: AngularFirestore) { }

  // Reviews that belong to a user
  getUserReviews(userId) {
    const reviewRef = this.afs.collection('reviews', ref => ref.where('userId', '==', userId).where('deleted', '==', false).orderBy('modified', 'desc') );
    return reviewRef.valueChanges();
  }

  // Get all reviews that belog to a place
  getPlaceReviews(placeId) {
    const reviewRef = this.afs.collection('reviews', ref => ref.where('placeId', '==', placeId).where('deleted', '==', false).orderBy('modified', 'desc') );
    return reviewRef.valueChanges();
  }

  // Create or update review
  setReview(userId, placeId, placeName, name, comment, price, meatReview, sauceReview, dumplingReview, creamReview, cranberryReview, overallReview) {
    
    const modified = firebase.firestore.FieldValue.serverTimestamp();
    const deleted = false;

    if(!price) {price = 0};

    // Review document data
    const review: Review = { modified, deleted, userId, name, comment, price, placeId, placeName, meatReview, sauceReview, dumplingReview, creamReview, cranberryReview, overallReview };

    // Custom doc ID for relationship
    const reviewPath = `reviews/${review.userId}+${review.placeId}`;

    // Set the data, return the promise
    return this.afs.doc(reviewPath).set(review)
  }

}
