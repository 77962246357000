import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { ReviewService } from '../services/review.service';
import { PlaceService } from '../services/place.service';
import { map } from "rxjs/operators";
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranService } from '../services/tran.service';

//to setup jQuery page stuff 
declare function jsFunctionSetupJQueryScript(): any;

export interface Place {
  name: any;
}

@Component({
  selector: 'app-place',
  templateUrl: './place.component.html',
  styleUrls: ['./place.component.scss']
})
export class PlaceComponent implements OnInit {

  userDoc: AngularFirestoreDocument<any>;
  placeDoc: AngularFirestoreDocument<any>;

  user: Observable<any>;
  place: Observable<any>;

  reviews: Observable<any>;
  avgRating: Observable<any>;

  comment: string = "";
  price: number;

  _meatReview: number = 0;
  _sauceReview: number = 0;
  _dumplingReview: number = 0;
  _creamReview: number = 0;
  _cranberryReview: number = 0;

  get meatReview() {
    return this._meatReview;
  }

  set meatReview(value: number) {
    this._meatReview = value;
    this.UpdateOverallReview();
  }

  get sauceReview() {
    return this._sauceReview;
  }

  set sauceReview(value: number) {
    this._sauceReview = value;
    this.UpdateOverallReview();
  }

  get dumplingReview() {
    return this._dumplingReview;
  }

  set dumplingReview(value: number) {
    this._dumplingReview = value;
    this.UpdateOverallReview();
  }

  get creamReview() {
    return this._creamReview;
  }

  set creamReview(value: number) {
    this._creamReview = value;
    this.UpdateOverallReview();
  }

  get cranberryReview() {
    return this._cranberryReview;
  }

  set cranberryReview(value: number) {
    this._cranberryReview = value;
    this.UpdateOverallReview();
  }




  overallReview: number = 0;

  reviewPostSucessful: boolean;
  photoPostSucessful: boolean;

  constructor(private route: ActivatedRoute,
    public auth: AuthService,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private reviewService: ReviewService,
    private placeService: PlaceService,
    private router: Router,
    public tranSerivce: TranService,
    public sanitizer: DomSanitizer) { }

  async ngOnInit() {
    this.tranSerivce.loadLang();

    this.reviewPostSucessful = false;
    this.photoPostSucessful = false;

    this.placeDoc = this.afs.doc(`places/${this.route.snapshot.params.id}`);
    this.place = this.placeDoc.valueChanges();

    jsFunctionSetupJQueryScript();

    /*
    this.userDoc;
    await this.auth.user$.subscribe(user => 
    {
      this.userDoc = this.afs.doc(`users/${user.uid}`);
      this.user = this.userDoc.valueChanges();
    });
    */

    this.reviews = this.reviewService.getPlaceReviews(this.placeId);

    this.avgRating = this.reviews.pipe(map(arr => {
      const ratings = arr.map(v => v.overallReview)
      return ratings.length ? ratings.reduce((total, val) => total + val) / arr.length : '0'
    }));
  }

  get placeId() {
    return this.placeDoc.ref.id
  }

  get userId() {
    return this.userDoc.ref.id
  }

  UpdateOverallReview() {
    this.overallReview = (this.meatReview + this.sauceReview + this.dumplingReview + this.creamReview + this.cranberryReview) / 5;
    this.overallReview = + (Math.round(this.overallReview * 2) / 2).toFixed(1);
  }

  saveReview(username: string, placeName: string) {
    var firstName = username.substr(0, username.indexOf(" "));
    this.reviewService.setReview(this.auth.loggedInUserId, this.placeId, placeName, firstName, this.comment, this.price, this.meatReview, this.sauceReview, this.dumplingReview, this.creamReview, this.cranberryReview, this.overallReview)
      .then(ref => (this.reviewPostSucessful = true));
  }

  uploadFile(event) {
    const file = event.target.files[0];
    const filePath = `reviews/${this.auth.loggedInUserId}/${this.route.snapshot.params.id}`;
    const ref = this.storage.ref(filePath);
    const task = ref.put(file);
    task.then(ret => this.photoPostSucessful = true)
      .catch(ret => console.log(ret));
  }

  getMapUrl(name: string, address: string, lat: string, long: string) {
    //return this.sanitizer.bypassSecurityTrustResourceUrl(`https://maps.google.com/maps?q=${lat},${long}&z=15&output=embed`);
    return this.sanitizer.bypassSecurityTrustResourceUrl(`https://maps.google.com/maps?q=${name}+${address}&z=15&output=embed`);
  }

  getFirmyUrl() {
    return `https://www.firmy.cz/detail/${this.route.snapshot.params.id}/`;
  }

  getReviewImage(userId: string, placeId: string) {
    return `https://firebasestorage.googleapis.com/v0/b/spickovasvickova.appspot.com/o/reviews%2F${userId}%2F${placeId}?alt=media`;
  }

}
