import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-review-rating',
  templateUrl: './review-rating.component.html',
  styleUrls: ['./review-rating.component.scss']
})


export class ReviewRatingComponent implements OnInit {

  @Input() title: string;
  @Input() desc: string;

  private _score: number;
  @Input() get score(): number { return this._score; }
  set score(value: number) { this.setScore(value) }

  @Output() scoreChange = new EventEmitter<number>();

  constructor() { }

  ngOnInit() {
  }

  setScore(value: number) {
    this._score = value;
    this.scoreChange.emit(value);
  }

}
