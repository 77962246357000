export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDLM0ntkXEiLtEa1nQKTy5FcMtAgEtnM9E",
    authDomain: "spickovasvickova.cz",
    databaseURL: "https://spickovasvickova.firebaseio.com",
    projectId: "spickovasvickova",
    storageBucket: "spickovasvickova.appspot.com",
    messagingSenderId: "194521005050",
    appId: "1:194521005050:web:436ed82418827686"
  }
};