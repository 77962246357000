import { Component, OnInit, Input } from '@angular/core';
import { ReviewService } from '../services/review.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {

  comment: string = "";
  
  meatReview: number = 0;
  sauceReview: number = 0;
  dumplingReview: number = 0;
  creamReview: number = 0;
  cranberryReview: number = 0;
  overallReview: number = 0;

  @Input() userId;
  @Input() placeId;
  @Input() name;

  constructor(private reviewService: ReviewService, private router: Router) { }

  ngOnInit() {
  }
  
  
}
