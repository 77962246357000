import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SuperSecretComponent } from './super-secret/super-secret.component';
import { AuthGuard } from './services/auth.guard';
import { PlaceComponent } from './place/place.component';
import { HomeComponent } from './home/home.component';
import { PexesoComponent } from './pexeso/pexeso.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'secret', component: SuperSecretComponent, canActivate: [AuthGuard] },
  { path: 'pexeso', component: PexesoComponent },
  { path: 'misto/:id/:name', component: PlaceComponent },
  { path: 'misto/:id', component: PlaceComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
