import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { registerLocaleData } from '@angular/common';
import localeEN from '@angular/common/locales/en-GB';
import localeCZ from '@angular/common/locales/cs';
registerLocaleData(localeEN, 'en');
registerLocaleData(localeCZ, 'cz');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(public auth: AuthService) {}
}
