import { Component, OnInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { ReviewService } from '../services/review.service';
import { PlaceService, Place, MapyPlace } from '../services/place.service';
import { TranService } from '../services/tran.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

//to setup Mapy
declare function jsFunctionSetupMapy(): any;
//for Mapy Callback
declare var homeComponent: any;

//to setup Mapy
declare function jsFunctionCloseAllModals(): any;

//to setup jQuery page stuff 
declare function jsFunctionSetupJQueryScript(): any;


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  placeDoc: AngularFirestoreDocument<any>;
  places: Observable<Place[]>;

  selectedPlace: MapyPlace;
  showSelectedPlaceError: boolean;

  constructor(public auth: AuthService,
    private afs: AngularFirestore,
    public placeService: PlaceService,
    public tranSerivce: TranService,
    private router: Router) {
  }

  async ngOnInit() {
    this.tranSerivce.loadLang();

    homeComponent = this;
    jsFunctionSetupMapy();
    jsFunctionSetupJQueryScript();

    this.places = this.placeService.places;
  }

  ngOnDestroy(): any {
    homeComponent = null;
  }

  selectPlaceData(response) {

    //if(data.data.iconUrl === 'http://api.mapy.cz/poiimg/icon/133')
    //5322 = Resturance
    //5329 = Hospoda (search for 'brezance'), 
    //5620 = Vinoteck (search for 'Vinotéka Blatnice')
    //5326 = Cafe (search for 'Café Lajka')
    //5316 = Beer (search for 'Minipivovar U Dvou koček')
    if ([5322, 5329, 5620, 5326, 5316].includes(response.data.poiTypeId)) {
      this.showSelectedPlaceError = false;
      const mapyPlace = response.data;
      this.selectedPlace = this.setSelectedPlace(mapyPlace.id, mapyPlace.title, mapyPlace.secondRow, mapyPlace.latitude, mapyPlace.longitude);
    }
    else {
      this.selectedPlace = null;
      this.showSelectedPlaceError = true;
    }
  }

  setSelectedPlace(id, name, address, latitude, longitude): MapyPlace {
    return { id, name, address, latitude, longitude };
  }

  async addPlace() {
    jsFunctionCloseAllModals();
    //Check if place exists first
    const newPlaceDoc = this.afs.doc(`places/${this.selectedPlace.id}`);
    var getDoc = newPlaceDoc.get().subscribe(doc => {
      if (!doc.exists) {
        //Create new place
        this.placeService.setPlace(this.selectedPlace.id, this.auth.loggedInUserId, this.selectedPlace.name, this.selectedPlace.address, this.selectedPlace.latitude, this.selectedPlace.longitude)
          .then(ret => this.router.navigate([`/misto/${this.selectedPlace.id}/${this.getFriendlyUrl(this.selectedPlace.name)}`]));
      } else {
        this.router.navigate([`/misto/${this.selectedPlace.id}/${this.getFriendlyUrl(this.selectedPlace.name)}`])
      }
    });
  }

  getFriendlyUrl(name: string) {
    return name.trim().toLowerCase()
      .replace(new RegExp(' ', 'g'), '-')
      .replace(new RegExp('á', 'g'), 'a')
      .replace(new RegExp('č', 'g'), 'c')
      .replace(new RegExp('ď', 'g'), 'd')
      .replace(new RegExp('é', 'g'), 'e')
      .replace(new RegExp('ě', 'g'), 'e')
      .replace(new RegExp('í', 'g'), 'i')
      .replace(new RegExp('ň', 'g'), 'n')
      .replace(new RegExp('ó', 'g'), 'o')
      .replace(new RegExp('ř', 'g'), 'r')
      .replace(new RegExp('š', 'g'), 's')
      .replace(new RegExp('ť', 'g'), 't')
      .replace(new RegExp('ú', 'g'), 'u')
      .replace(new RegExp('ů', 'g'), 'u')
      .replace(new RegExp('ý', 'g'), 'y')
      .replace(new RegExp('ž', 'g'), 'z');
  }

}
