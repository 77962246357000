import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranService {

  isEnglish: boolean = true;

  constructor(public translate: TranslateService) {}
  
  loadLang() {
    // this language will be used as a fallback when a translation isn't found in the current language
     //this.translate.setDefaultLang('en');
     
    const lang = JSON.parse(localStorage.getItem('lang'));
    if (lang === 'cz'){
     this.translate.use('cz');
     this.isEnglish = false;
    }
    else {
     this.translate.use('en');
     this.isEnglish = true;
    }
  }

  changeLang(lang: string) {
    this.translate.use(lang);
    this.isEnglish =! this.isEnglish;
    localStorage.setItem('lang', JSON.stringify(lang));
  }
}
